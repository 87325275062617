blockquote {
  p.h3-like {
    @media only screen and (max-width: $breakPoint850) {
      font-size: 2.25rem;
      margin: 0 0 0.5em 0;
      line-height: 1.4em;
    }
  }
  cite.h2-like {

  }
}
