.text-overlay {
  position: relative;
  font-size: 0;
  line-height: 0;

  .text-overlay__content {
    position: absolute;
    z-index: 5;

    top: 0;
    width: 100%;
    height: 100%;
    padding: 25px;

    color: $color-white;
  }
}

// text overlay mobil
@media only screen and (max-width: $breakPoint1000) {
  .text-overlay__mobil {
    min-height: 500px;
  }

  .text-overlay__mobil--big {
    min-height: 700px;
  }
}

