.img-change-section {
  height: 100vh;
  width: 100%;
  min-height: 600px;

  .text-overlay__content {
    @media only screen and (max-width: $breakPoint850) {
      padding-top: calc(var(--header-height) - 15px);
    }
    @media only screen and (max-width: $breakPoint540) and (min-height: 681px) and (max-height: 950px){
      padding-top: calc(var(--header-height) + 15px);
    }
  }

  .button-primary.button-left {
    @media only screen and (max-width: $breakPoint540) and (max-height: 667px) {
      margin: calc(100vh - (var(--header-height) + 217px)) auto 0 auto;
    }
    @media only screen and (max-width: $breakPoint540) and (max-height: 759px) {
      margin: 56vh auto 0 auto;
    }
    @media only screen and (max-width: $breakPoint540) and (min-height: 760px) and (max-height: 950px){
      margin: 20px auto 0 0;
    }
  }

  .text-overlay__content .grid__col--11 {
    @media only screen and (min-width: $breakPoint850) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  //classes for the color of each background image for the text
  .img-change-section__text {
    transition: color .5s ease-in-out;
  }

  &.js-dot-1 {
    .img-change-section__text {
      color: $color-dot1;
    }
  }

  &.js-dot-2 {
    .img-change-section__text {
      color: $color-dot2;
    }
  }

  &.js-dot-3 {
    .img-change-section__text {
      color: $color-dot3;
    }
  }

  &.js-dot-4 {
    .img-change-section__text {
      color: $color-dot4-text;
    }
  }

  &.js-dot-5 {
    .img-change-section__text {
      color: $color-dot5;
    }
  }

  &.js-dot-6 {
    .img-change-section__text {
      color: $color-dot6;
    }
  }

  &.js-dot-7 {
    .img-change-section__text {
      color: $color-dot7-text;
    }
  }

  &.js-dot-8 {
    .img-change-section__text {
      color: $color-dot8-text;
    }
  }


  @media only screen and (max-width: $breakPoint850) {
    .grid__col--11 {
      grid-column: auto / span 11;
    }

    .grid__col--1 {
      grid-column: auto / span 1;
    }
  }

}

.img-change-section__images {
  position: relative;
  width: 100%;
  height: 100%;
}

.img-change-section__picture {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.js-active {
    opacity: 1;
  }
}


.dot-panel__col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
  @media only screen and (max-width: $breakPoint540) and (min-height: 760px) and (max-height: 950px){
    margin-bottom: 5vh;
  }
}


.dot-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $color-grey-opacity;

  width: 45px;
  border-radius: 20px;

  padding: 10px;
}

.dot__wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;

  @media only screen and (min-width: $breakPoint540) {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.js-active,
  &:hover {
    .dot {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }
  }

  &.js-active {
    .dot__text {
      opacity: 1;
    }
  }

  @media only screen and (min-width: $breakPoint540) {
    &:hover {
      .dot__text {
        opacity: 1;
      }
    }
  }

  // color for dots
  &.dot__wrapper--01 {
    .dot__text {
      color: $color-dot1;
    }

    .dot {
      background: $color-dot1;
    }
  }

  &.dot__wrapper--02 {
    .dot__text {
      color: $color-dot2;
    }

    .dot {
      background: $color-dot2;
    }
  }

  &.dot__wrapper--03 {
    .dot__text {
      color: $color-dot3;
    }

    .dot {
      background: $color-dot3;
    }
  }

  &.dot__wrapper--04 {
    .dot__text {
      color: $color-dot4-text;
    }

    .dot {
      background: $color-dot4;
    }
  }

  &.dot__wrapper--05 {
    .dot__text {
      color: $color-dot5;
    }

    .dot {
      background: $color-dot5;
    }
  }

  &.dot__wrapper--06 {
    .dot__text {
      color: $color-dot6;
    }

    .dot {
      background: $color-dot6;
    }
  }

  &.dot__wrapper--07 {
    .dot__text {
      color: $color-dot7-text;
    }

    .dot {
      background: $color-dot7;
    }
  }

  &.dot__wrapper--08 {
    .dot__text {
      color: $color-dot8-text;
    }

    .dot {
      background: $color-dot8;
    }
  }
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.dot__text {
  white-space: nowrap;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin-bottom: 0;
  line-height: 1em;

  bottom: calc(100% + 10px);
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: $breakPoint540) {
    font-size: 1rem;
  }

  @media only screen and (min-width: $breakPoint540) {
    right: calc(100% + 24px);
    left: auto;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

// mobil styles


@media only screen and (max-width: $breakPoint540) {
  .img-change-section {
    .grid__col--11,
    .grid__col--1 {
      grid-column: 1 / span 12;
    }
  }

  .dot-panel__col {
    flex-direction: row;
  }

  .dot-panel {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
  }


  .dot {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

}
