.swiper-button-next, .swiper-button-prev {
  img {
    transition: transform 0.3s ease-in-out;
  }
}

.swiper-button-prev:hover {
  img {
    transform: translateX(-10px);
  }
}

.swiper-button-next:hover {
  img {
    transform: translateX(10px);
  }
}


