.language-select__container--outer {
  display: flex;
  align-items: center;
}

.language-select__container {
  color: $color-white;
  margin-right: 15px;

  .language-select__active {
    border-bottom: 1px solid;
    cursor: pointer;
  }
}

.language-select__menu {
  display: none;
  position: relative;

  &.active {
    position: absolute;
    display: flex;
    flex-direction: column;
  }
}

.language-select__menu--item {
  padding-top: 7px;
  color: $color-white;
}