$columnWidths: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

.grid-12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 0;
  box-sizing: border-box;
  height: 100%;

  @each $column in $columnWidths {
    .grid__col--#{$column} {
      grid-column: auto / span $column;

      @media only screen and (max-width: $breakPoint850) {
        grid-column: 1 / span 12;
      }
    }
  }
}

.grid--wide-gap {
  grid-column-gap: 80px;

  @media only screen and (max-width: $breakPoint1200) {
    grid-column-gap: 40px;
  }

  @media only screen and (max-width: $breakPoint850) {
    grid-column-gap: 20px;
  }
}

.grid-10 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 0;
  box-sizing: border-box;
  height: 100%;

  @each $column in $columnWidths {
    .grid__col--#{$column} {
      grid-column: auto / span $column;

      @media only screen and (max-width: $breakPoint850) {
        grid-column: 1 / span 10;
      }
    }
  }
}

.grid-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 0;
  box-sizing: border-box;
  height: 100%;

  @each $column in $columnWidths {
    .grid__col--#{$column} {
      grid-column: auto / span $column;

      @media only screen and (max-width: $breakPoint850) {
        grid-column: 1 / span 6;
      }
    }
  }
}

@media only screen and (max-width: $breakPoint850) {
  .grid__col--filler {
    display: none;
  }
}

// -----------------------------
// --------- Flex Cols ---------
// -----------------------------
.flex-2col {
  display: flex;
  flex-direction: row;

  .flex-col__50 {
    width: 50%;
  }
}

@media only screen and (max-width: $breakPoint850) {
  .flex-2col {
    flex-direction: column;

    .flex-col__50 {
      width: 100%;

      margin-top: -1px;
    }
  }
}

.flex-3col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .flex-col__33 {
    width: 33.333333333333333%;
  }
}



