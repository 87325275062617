header {
  height: 100vh;
  min-height: 600px;

  .autoplay-video {
    height: 100vh;
  }
}

// animation for heading
header.scaling-section {
  &.js-in-viewport {
    .scaling-section__text {
      animation: opacity-time-offset 4.5s ease-in-out, margin-time-offset 4.55s ease-in-out;
      margin-top: 0;
      opacity: 1;
    }
  }
}