.image-change-on-hover {
  position: relative;

  .image-change-on-hover__image {
      transition: opacity 0.5s;
  }

  .image-change-on-hover__image--initial {
   display: none;
    @media screen and (min-width: #{$breakPoint950}) {
      display: block;
      opacity: 1;
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
    }
  }

  .image-change-on-hover__image--on-hover{
    width: 100%;
    height: auto;
    @media screen and (min-width: #{$breakPoint950}) {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
    }

    .autoplay-video {
      height: auto;
    }
  }
}

.text-overlay:has(.image-change-on-hover):hover,
.image-change-on-hover:hover {
  .image-change-on-hover__image--initial {
    opacity: 0;
  }

  .image-change-on-hover__image--on-hover {
    opacity: 1;
  }
}
