body.header-color-scheme--dark {
  .nav-logo__svg {
    color: #{$color-white};
  }
}

body.header-color-scheme--light,
body.contact-form-is-active,
body.header-color-scheme--dark.contact-form-is-active {
  .nav-logo__svg {
    color: #{$color-black};
  }

  .language-select__container,
  .language-select__menu--item {
    color: $color-black;
  }


  .main-nav .button-primary {
    background: #{$color-black};
  }
}
