.page-footer {
  padding: 50px 0 50px 0;
  background-color: $color-background-footer;
  color: $color-grey-font;
  z-index: 35;
  position: relative;

  a {
    color: $color-grey-font;
    @include hover-line;

    &:hover {
      color: $color-white;
      text-decoration: none;
      @include hover-line-hover;
    }
  }
}

.footer-list {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media only screen and (min-width: $breakPoint1200) {
    gap: 50px;
  }

  & > li , a {
    font-size: 12px;
    line-height: 1.8;
    color: #959595;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
}

.social-media-list {
  display: flex;
  flex-direction: row;
  row-gap: 15px;
  column-gap: 50px;
  flex-wrap: wrap;

  @media only screen and (min-width: $breakPoint1200) {
    gap: 50px;
  }

  @media only screen and (max-width: $breakPoint1200) {
    column-gap: 35px;
  }

  @media only screen and (max-width: $breakPoint950) {
    column-gap: 25px;
  }

  @media only screen and (max-width: $breakPoint850) {
    column-gap: 40px;
  }

  .social-media__link {
    display: block;
  }

  .social-media__icon {
    width: 12px;
    height: 13px;
    max-width: 15px;
    fill: #fff;
    object-fit: contain;

    @media only screen and (max-width: $breakPoint480) {
      width: 20px;
      height: 22px;
    }

  }
}
