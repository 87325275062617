@font-face {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Myriad Pro'), url('../fonts/MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Myriad Pro Bold'), url('../fonts/MyriadPro-Bold.woff') format('woff');
}

html {
  font-family: 'Myriad Pro', Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-size: 10px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;

  * {
    z-index: 1;
  }
}

body {
  font-size: 16px;
  line-height: 1.4;
}


* {
  &::selection {
    color: $color-white;
    background: $color-call-to-action; /* WebKit/Blink Browsers */
  }

  &::-moz-selection {
    color: $color-white;
    background: $color-call-to-action; /* Gecko Browsers */
  }
}

strong {
  font-weight: 700;
  font-family: 'Myriad Pro Bold', 'Myriad Pro', Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.color-grey {
  color: $color-grey-font;
}

h1, .h1-like {
  font-size: 10rem;
  line-height: 1.2em;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.006em;
  @media only screen and (max-width: $breakPoint850) {
    font-size: 6rem;
  }

  @media only screen and (max-width: $breakPoint480) {
    max-width: 300px;
    margin: 0 auto;
  }
}

.small-h1 {
  display: block;
  font-size: 3.4rem;
  line-height: 1.2em;
  letter-spacing: normal;
  font-weight: 400;
  @media only screen and (max-width: $breakPoint850) {
    font-size: 3rem;
  }
}

span.h1-like + .small-h1 {
  margin-top: 10px;
  @media only screen and (max-width: $breakPoint850) {
    margin-top: 10px;
  }
}


h2,
.h2-like {
  font-size: 1.4rem;
  margin: 0 0 2em 0;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}


h3, .h3-like {
  font-size: 3.5rem;
  margin: 0 0 0.5em 0;
  line-height: 42px;
  @media only screen and (max-width: $breakPoint850) {
    font-size: 2.5rem;
    margin: 0 0 0.5em 0;
    line-height: 1.4em;
  }
}

h4 {
  font-size: 2.6rem;
  line-height: 32px;
  margin-bottom: 15px;
}

h5 {
  font-size: 60px;
  line-height: 70px;
  @media only screen and (max-width: $breakPoint850) {
    font-size: 40px;
    line-height: 50px;
  }
}

p {
  font-size: 3.5rem;
  line-height: 42px;
  @media only screen and (max-width: $breakPoint850) {
    font-size: 1.65rem;
    line-height: 1.4em;
  }
}

p.p-big {
  font-size: 6rem;
  line-height: 70px;
  letter-spacing: 0.006em;
  @media only screen and (max-width: $breakPoint1400) {
    font-size: 4.5rem;
    line-height: 55px;
  }
  @media only screen and (max-width: $breakPoint850) {
    font-size: 1.65rem;
    line-height: 1.4em;
  }
}

p.p-medium {
  font-size: 4.5rem;
  line-height: 50px;
  @media only screen and (max-width: $breakPoint1400) {
    font-size: 3.5rem;
    line-height: 45px;
  }
  @media only screen and (max-width: $breakPoint1200) {
    font-size: 3rem;
    line-height: 40px;
  }
}

p.p-small {
  font-size: 2.6rem;
  line-height: 32px;
  @media only screen and (max-width: $breakPoint850) {
    font-size: 1.65rem;
    line-height: 1.4em;
  }
}

p.p-smaller {
  font-size: 1.6rem;
  line-height: 22px;
  @media only screen and (max-width: $breakPoint850) {
    font-size: 1.6rem;
    line-height: 22px;
    letter-spacing: 0.3px;
  }
}

hr {
  width: 100%;
  color: inherit;

  border: unset;
  height: 1px;
  background-color: black;


  margin: 5px 0;
  @media only screen and (max-width: $breakPoint850) {
    margin: 2px 0;
  }
}

figcaption {
  margin-top: 10px;
}

// Links
a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $color-call-to-action;
    text-decoration: underline;
  }

  &.glightbox {
    text-decoration: none;
  }
}

a.textlink {
  text-decoration: underline;
  color: inherit;
}

a.tablink {
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    .tablink__headline {
      @include hover-line-hover;
    }
  }

  p {
    margin-bottom: 0;
  }

  .tablink__headline {
    @include hover-line;
  }

}


// lists
main {
  ul {
    list-style: disc inside;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1em;
    }
  }
}

// Buttons
.button-primary {

  font-family: 'Myriad Pro', Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;

  cursor: pointer;
  text-decoration: none;

  background-color: $color-call-to-action;
  border: 2px solid $color-call-to-action;
  color: $color-white;

  font-size: 18px;
  line-height: 22px;

  padding: 8px 25px;

  margin: 40px auto 0 auto;

  border-radius: 50px;

  display: flex;
  align-items: center;
  transition: color .2s ease-in-out, background-color .2s ease-in-out;
  justify-content: center;

  &:hover {
    background-color: $color-alt-button;
    color: $color-black;
    border: 2px solid $color-call-to-action;
    text-decoration: none;

  }

  @media only screen and (max-width: $breakPoint400) {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 20px;
  }
}

a.button-primary {
  display: inline-block;
}

.button-primary.button-left {
  margin: 40px auto 0 0;
}

.button-primary.button-alt-style {
  background-color: $color-alt-button;
  border: none;
  color: $color-white;

  &:hover {
    background-color: $color-alt-button-hover;
  }
}

.text-align__center {
  text-align: center;
}

.justify-content__center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.justify-content__end {
  display: flex;
  flex-direction: column;
  justify-content: end;
}


.align-items__end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
