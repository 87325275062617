.tab-element {
  background-color: $color-background-tabs;
  color: $color-white;

  padding: 6rem 5.55556%;

  @media (min-width: 768px) {
    padding-left: 11.11111%;
    padding-right: 11.11111%;
  }

  p:not(.h3-like), li, a {
    font-size: 18px;
    line-height: 1.5;
  }

  ul {
    margin-top: 25px;
  }

}

.tab-navigation {
  border-bottom: solid $color-grey 1px;
  display: flex;
  margin-bottom: 50px;
  @media only screen and (max-width: $breakPoint850) {
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .dragscroll {
    cursor: all-scroll;
  }
}

.tab-navigation__wrapper {
  @media only screen and (max-width: $breakPoint850) {
    overflow: hidden;
  }
}


.tab-navigation__item {
  padding: 0 25px 10px 25px;
  cursor: pointer;
  white-space: nowrap;
  color: $color-grey;
  font-size: 20px;

  @include hover-line;

  @media only screen and (max-width: $breakPoint850) {
    font-size: 16px;
  }

  @media only screen and (max-width: $breakPoint680) {
    padding-right: 30px;
  }

  @media only screen and (max-width: $breakPoint600) {
    padding: 25px;
  }

  &.active,
  &:hover {
    color: $color-white;
    @include hover-line-hover;
  }

  &:first-child {
    padding-left: 0;
    @media only screen and (max-width: $breakPoint600) {
      padding-left: 25px;
    }
  }

  &:last-child {
    @media only screen and (max-width: $breakPoint600) {
      padding-right: 25px;
    }
  }

}

.tab-panel {
  display: none;

  &.tab-panel__padding-bottom {
    padding-bottom: 50px;
  }
}

.tab-panel {
  img {
    height: auto;
  }
}

.tab-panel.active {
  display: block;
  animation: fade-in 1.5s ease-out;
}

.tab-content {
  animation: fade-in 1.5s ease-out;
}
