.scaling-section {

  overflow: hidden;
  margin-bottom: -1px;

  .scaling-section__img {
    transform: scale(0.8);

    transition: transform 1s ease-in-out;
  }

  .scaling-section__text {
    opacity: 0;
    margin-top: 20px;
  }

  picture,
  img {
    max-height: unset;
  }


  &.js-in-viewport {

    .scaling-section__img {
      transform: scale(1);
    }

    .scaling-section__text {
      animation: opacity-time-offset 1.9s ease-in-out, margin-time-offset 1.95s ease-in-out;
      margin-top: 0;
      opacity: 1;
    }
  }
}

.scaling-section__img .swiper img {
  max-height: 100vh;
}
