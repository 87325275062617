section {
  background-color: $color-white;
  position: relative;
}

// ------------------------------------------------------------------------------
// -- sections händisch durchgezählt safari hatte probleme mit der funktion -----
// ------------------------------------------------------------------------------
//$sectionCount: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14;
//
//@each $section in $sectionCount {
//  .section--#{$section} {
//    z-index: $section;
//  }
//}

.section--1 { z-index: 1;}
.section--2 { z-index: 2;}
.section--3 { z-index: 3;}
.section--4 { z-index: 4;}
.section--5 { z-index: 5;}
.section--6 { z-index: 6;}
.section--7 { z-index: 7;}
.section--8 { z-index: 8;}
.section--9 { z-index: 9;}
.section--10 { z-index: 10;}
.section--11 { z-index: 11;}
.section--12 { z-index: 12;}
.section--13 { z-index: 13;}
.section--14 { z-index: 14;}
.section--15 { z-index: 15;}
.section--16 { z-index: 16;}
.section--17 { z-index: 17;}
.section--18 { z-index: 18;}

.section-padding__both--large {
  padding-top: 200px;
  padding-bottom: 200px;
}

.section-padding__both--medium {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-padding__both--small {
  padding-top: 70px;
  padding-bottom: 70px;
}

.section-padding__bottom--large {
  padding-bottom: 200px;
}

.section-padding__bottom--medium {
  padding-bottom: 120px;
}

.section-padding__bottom--small {
  padding-bottom: 70px;
}



@media only screen and (max-width: $breakPoint850) {
  .section-padding__both--large {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-padding__both--medium {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-padding__both--small {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .section-padding__bottom--large {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-padding__bottom--medium {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-padding__bottom--small {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.section--7 {
  .grid-12 {
    padding: 50px 0 70px 0;
  }
  .grid-12:last-child {
    padding-bottom: 0;
  }

  &.section-padding__both--large {
    @media only screen and (max-width: $breakPoint850) {
      padding-top: 50px;
      padding-bottom: 50px;
    }


  }

}
