picture {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100vh;
}

// hide broken images
img:not([src]):not([srcset]) {
  visibility: hidden;
}

img {
  display: block;
}

img,
video {
  width: 100%;
  height: 100%;
  max-height: 100vh;

  object-fit: cover;
}
