// =========
/// Colors
// =========

$color-primary: #344B3F;
$color-secondary: #FAF9F8;
$color-white: #ffffff;
$color-black: #000000;
$color-call-to-action: #000000;
$color-background: #2E2D2D;
$color-background-tabs: #2b2929;
$color-background-footer: #2b2929;
$color-grey: #BDBDBD;
$color-grey-font: #959595;
$color-grey-opacity: rgba(217, 217, 217, 0.3);

$color-alt-button: rgba(198, 198, 198, 0.3);
$color-alt-button-hover: rgba(79, 79, 79, 0.3);

$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

$color-input-border: #c5c5c5;
$color-input-text: $color-background;
$color-label: $color-input-border;
$color-label-hover: $color-black;
$color-check-bg: $color-white;
$color-check-bg-hover: #f1f1f1;
$color-check-bg-active: $color-black;


// color for dots and the ray work chairs
$color-dot1: #000000;
$color-dot2: #616161;
$color-dot3: #9b9b9b;
$color-dot4: #9C6C60;
$color-dot4-text: #6f4135;
$color-dot5: #7b6f59;
$color-dot6: #4B5673;
$color-dot7: linear-gradient(90deg, #C2C2C2, #C2C2C2 50%, #000000 50%);
$color-dot7-text: #262626;
$color-dot8: linear-gradient(90deg, #C2C2C2, #C2C2C2 50%, #888888 50%);
$color-dot8-text: #9b9b9b;


// media queries - breakpoints
$breakPoint1800: 1800px;
$breakPoint1700: 1700px;
$breakPoint1600: 1600px;
$breakPoint1500: 1500px;
$breakPoint1400: 1400px;
$breakPoint1300: 1300px;
$breakPoint1201: 1201px;
$breakPoint1200: 1200px;
$breakPoint1100: 1100px;
$breakPoint1000: 1000px;
$breakPoint950: 950px;
$breakPoint900: 900px;
$breakPoint850: 850px;
$breakPoint780: 780px;
$breakPoint680: 680px;
$breakPoint600: 600px;
$breakPoint540: 540px;
$breakPoint480: 480px;
$breakPoint400: 400px;

:root {
  --header-height: 85px;
}

// animations

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes opacity-time-offset {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes margin-time-offset {
  0% {
    margin-top: 20px;
  }
  50% {
    margin-top: 20px;
  }
  60% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}
