.section-explosionszeichnung {

  .grid__col--2.justify-content__end {
    @media only screen and (min-width: $breakPoint1400) {
      margin-bottom: 10vh;
    }
  }

    @media only screen and (max-width: $breakPoint1400) {
      .grid__col--9 {
        grid-column: auto / span 9;
      }

      .grid__col--2 {
        grid-column: auto / span 4;
      }
    }

  @media only screen and (max-width: $breakPoint850) {
    .grid__col--9 {
      display: none;
    }

    .grid__col--2 {
      grid-column: auto / span 12;
    }
  }
}
