.autoplay-video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

///////////////////////////

//.video-background,
//.autoplay-video__video {
//  position: fixed;
//  top: 50%;
//  left: 50%;
//  min-width: 100%;
//  min-height: 100%;
//  transform: translate(-50%, -50%);
//}
//
//#container,
//.autoplay-video {
//  height: 200vh;
//}
