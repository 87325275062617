main ul {
  list-style: none;
  li {
    position: relative;
    padding-left: 15px;
    &:before {
      content: "• ";
      position: absolute;
      top: 3px;
      left: 0;
      font-size: 10px;
      color: $color-white;
    }
  }
}
