.overflow-slider {
  overflow-x: scroll;
  overflow-y: clip;

  max-height: 80vh;

  //Disable scroll bar
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  display: flex;
  gap: 25px;

  .overflow-slider__item {
    flex-shrink: 0;

    pointer-events: none;

    img {
      max-height: 70vh;
      height: auto;

      @media only screen and (max-width: $breakPoint850) {
        max-height: 400px;
        max-width: 565px;
        height: auto;

        object-fit: contain;
        margin: 0;
        padding: 0;
      }

    }
  }

}


.overflow-slider__arrow--container {
  position: relative;
  height: 75px;

  .overflow-slider__arrow {
    display: none;
  }

  &.js-arrow-right {
    .overflow-slider__arrow--next {
      display: block;
      right: 0;
    }
  }

  &.js-arrow-left {
    .overflow-slider__arrow--prev {
      display: block;
      left: 0;
    }
  }

  .overflow-slider__arrow {
    padding: 25px;
    box-sizing: content-box;
    position: absolute;
    top: 0;

    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  .overflow-slider__arrow--prev:hover {
    transform: translateX(-10px);
  }

  .overflow-slider__arrow--next:hover {
    transform: translateX(10px);
  }
}
