.contact-form__container {
  position: fixed;
  overflow-y: scroll;
  z-index: 200;

  top: auto;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 0;

  background-color: $color-white;
  transition: height 0.5s;

  * {
    opacity: 0;
  }

  &.active {
    height: 100vh;
    z-index: 400;
    padding-top: 40px;

    * {
      opacity: 1;
    }

    h2 {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 55px;
    line-height: 65px;
    letter-spacing: 0.006em;

    margin-bottom: 75px;
    text-transform: none;

    max-width: 800px;

    margin-top: 200vh;
  }
}


// form
form {

  padding-bottom: 25px;
  @media only screen and (max-width: $breakPoint1100) {
    padding-bottom: 25px;
  }

  @media only screen and (max-width: $breakPoint850) {
    padding-bottom: 20px;
  }

  .padding-bottom {
    padding-bottom: 20px;
  }

  .color-grey {
    width: 250px;
  }

  .form-row {
    display: flex;
  }

  .form-group {
    padding-bottom: 22px;
    padding-right: 15px;
    padding-top: 15px;

    flex-basis: 600px;

    .form-sub-group {
      margin-right: 10px;
    }

    .form-group__flex-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    &.flex-group {
      display: flex;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  // label
  label {
    display: block;
    position: relative;
    color: $color-label;
    font-size: 20px;
    line-height: 1.5;
    transform: translateY(95%);
    transition: all 350ms ease-in-out;
    height: 30px;
    z-index: 5;
  }

  .form-row-checkbox,
  .form-group__flex-item label,
  .form-group--textarea label {
    height: auto;
    font-size: 16px;
    color: $color-label-hover;
    transform: none;
  }

  .form-group.is-active {
    label {
      font-size: 12px;
      transform: translateY(0);
    }
  }


  input[type=text],
  input[type=email],
  input[type=date],
  input[type=number],
  input[type=tel],
  textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-input-border;

    color: $color-input-text;
    font-family: inherit;

    padding: 5px 0;

    &:focus-visible {
      border-color: $color-black;
      outline: none;
    }
  }

  textarea {
    display: block;
    border: 1px solid $color-input-border;
    color: $color-input-text;
    min-height: 180px;
    width: 100%;
    padding: 17px;
    transition: color 200ms, border-color 200ms;
    resize: vertical;
    line-height: 1.5;

    &:focus,
    &:hover {
      outline: none;
      border-color: #000;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 5px;
    width: 14px;
    height: 14px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    &:hover + .checkmark {
      background-color: $color-check-bg-hover;
    }
    &:checked + .checkmark {
      background: $color-check-bg-active;
      border: 1px solid $color-check-bg-active;
    }
  }

  .checkbox__text-content {
    padding-left: 25px;
  }

  .checkmark {
    position: absolute;
    display: block;
    left: 0;
    top: 3px;
    width: 14px;
    height: 14px;
    background: $color-check-bg;
    border: 1px solid $color-check-bg-active;
  }

  .checkbox {
    label {
      font-size: inherit;
    }
  }

  //custom radio button
  input[type="radio"] {
    background-color: $color-check-bg;
    border: .0625em solid $color-check-bg-active;
    border-radius: 50%;
    cursor: pointer;
    font: inherit;
    height: 1em;
    outline: none;
    width: 1em;
    margin-left: 20px;
    margin-right: 10px;
    margin-top: 0;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:hover {
        background-color: $color-check-bg-hover;
    }
    &:checked {
      background-color: $color-check-bg-active;
      transition: background .15s;
    }
  }


  // checkbox label & input
  .form-check {
    .form-check-label {
      display: flex;
      align-items: baseline;

      input {
        margin: 0 8px 0 0;
        flex-shrink: 0;
      }
    }
  }

  // validation
  .error {
    color: $color-call-to-action;
  }
}


@media only screen and (max-width: $breakPoint900) {
  form {
    .form-row {
      flex-direction: column;

      .form-group {
        flex-basis: unset;


        .form-group__flex-item {
          input[type="radio"] {
            margin-left: 0;
          }

          margin-right: 15px;
          margin-top: 10px;

          &:last-child {
            margin-right: 0;
          }
        }


      }
    }
  }
}

@media only screen and (max-width: $breakPoint850) {
  .contact-form__container {
    &.active {
      h2 {
        font-size: 35px;
        line-height: 42px;
        max-width: 600px;
        margin-bottom: 25px;
      }
    }
  }
}

@media only screen and (max-width: $breakPoint680) {
  form {
    .form-row {
      .form-group {
        display: flex;
        flex-direction: column;

        input[type="radio"] {
          margin: 0 5px 0 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakPoint600) {
  .contact-form__container {
    &.active {
      h2 {
        font-size: 30px;
        line-height: 35px;
      }
    }
  }
}


.form-field__exclusive {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}