.show-more-element {
  position: relative;

  width: 22px;
  height: 22px;

  box-sizing: content-box;
  padding: 20px 20px 20px 0;

  cursor: pointer;

  &:hover {
    .plus-span {
      background-color: $color-grey;
    }
  }

  .plus-span {
    position: absolute;
    display: block;
    width: 22px;
    height: 1px;
    background-color: $color-black;
    transition: all 155ms ease-in-out;
  }

  .plus-span-1 {
    height: 0.5px;
    top: 30px
  }

  .plus-span-2 {
    height: 1px;
    top: 31px;
    transform: rotate(-90deg);
  }

  &.active {
    .plus-span-2 {
      transform: rotate(0deg);
      top: 30px;
    }

    &:hover .plus-span {
      background-color: $color-black;
    }
  }


}

.show-more-element__wrapper {
  position: relative;

  &.show-more-element--position-above {
    .show-more-element__content {
      display: block;
      opacity: 0;

      &.active {
        display: block;
        opacity: 1;
      }
    }
  }

  &.show-more-element--position-below {

    .show-more-element {
      padding-right: 0;
      padding-left: 20px;
    }

    .show-more-element__content {
      display: block;
      visibility: hidden;
      pointer-events: none;

      &.active {
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  &.show-more-element--white {
    .plus-span {
      background-color: $color-white;
    }

    &:hover {
      .plus-span {
        background-color: $color-black;
      }
    }

    &.active {
      &:hover .plus-span {
        background-color: $color-white;
      }
    }
  }
}

.show-more-element__content {
  display: none;

  &.active {
    display: block;
    animation: fade-in 0.5s ease-out;
  }
}
