.main-wrap {
  padding-left: 25px;
  padding-right: 25px;
}

.main-wrap__left {
  padding-left: 25px;
}

.main-wrap__right {
  padding-right: 25px;
}

.main-wrap__bottom {
  padding-bottom: 25px;
}