.section-jehs-laub {

  @media only screen and (max-width: $breakPoint1400) {
    //.grid__col--3 {
    //  grid-column: auto / span 8;
    //}
    //
    //.grid__col--6 {
    //  grid-column: auto / span 4;
    //}

    .grid__col--2 {
      grid-column: auto / span 3;
    }
  }

  @media only screen and (max-width: $breakPoint1200) {
    .grid__col--3 {
      grid-column: auto / span 2;
    }

    .grid__col--6 {
      grid-column: auto / span 8;
    }

    .grid__col--2 {
      grid-column: 3 / span 8;
    }
  }

  @media only screen and (max-width: $breakPoint850) {
    .grid__col--3,
    .grid__col--6,
    .grid__col--2{
      grid-column: auto / span 12;
    }
  }



}