@mixin hover-line {
  background-repeat: no-repeat;
  background-image: linear-gradient(transparent 0, #fff 0);
  background-color: transparent;
  background-size: 0% 5%;
  background-position: 100% 100%;
  transition: color .3s ease-in-out, background-size 0.8s cubic-bezier(0.2,0,0.1,1);
}
@mixin hover-line-hover {
  background-size: 100% 5%;
  background-position: 0% 100%;
}
