.sticky-section {
  overflow: hidden;

  //position: sticky;
  //position: -webkit-sticky;
  //top: 0;

  position: relative !important;

  max-height: 100vh;
  box-sizing: content-box;
}


///* Safari 10.1+ (alternate method) */
//@media not all and (min-resolution: .001dpcm) {
//  @supports (-webkit-appearance:none) {
//
//    .sticky-section {
//      position: relative !important;
//    }
//  }
//}
///* Safari 9+ */
//_:default:not(:root:root), .safari_only {
//
//  .sticky-section {
//    position: relative !important;
//  }
//}
//
//
///*--- Only iOS Safari ≥ 9 ---*/
//
//@media screen and (-webkit-min-device-pixel-ratio: 0) {
//  .sticky-section {
//    position: relative !important;
//  }
//}
