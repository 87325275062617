.main-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 500;


  .nav-logo__link {
    display: block;;
    height: fit-content;
  }

  .button-primary {
    margin: 0;
  }
}

.nav-logo__svg {
  width: 110px;
  object-fit: contain;
  color: $color-white;

  @media only screen and (max-width: $breakPoint400) {
    width: 85px;
  }
}

.main-nav__col-right {
  display: flex;
  flex-wrap: nowrap;

  position: fixed;
  right: 25px;
}

// styles for in form header always sticky
.contact-form-is-active {

  .main-nav {
    height: 77px;
  }

  .nav-logo__link {
    display: none;
  }

  .contact-form__logo {
    display: block;
    margin-bottom: 80px;

    @media only screen and (max-width: $breakPoint850) {
      margin-bottom: 120px;
    }
  }
}

// styles for mobil header not sticky but on form is visible
@media only screen and (max-width: $breakPoint850) {
  .main-nav,
  .main-nav__col-right {
    position: absolute;
  }

  .contact-form-is-active {
    .main-nav,
    .main-nav__col-right {
      position: fixed;
    }
  }
}